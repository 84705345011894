import axios from "axios";

const url = "https://recepcion.kilometro314.com.ar/api/APIRecepcion.php";

export const loginUser = async (user, password) => {
  try {
    const res = await axios.post(url, {
      usuario: user,
      clave: password,
      opcion: "iniciarSesion",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const postForm = async (
  visitors,
  vehicles,
  dateIn,
  dateOut,
  lot,
  user
) => {
  try {
    const res = await axios.post(url, {
      personas: visitors,
      vehiculos: vehicles,
      fecha_ingreso: dateIn,
      fecha_egreso: dateOut,
      lote: lot,
      idUsuario: user,
      opcion: "insertarFormulario",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getForms = async () => {
  try {
    const res = await axios.post(url, {
      opcion: "getTop20",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getFormVisitorsById = async (id) => {
  try {
    const res = await axios.post(url, {
      IDFormulario: id,
      opcion: "getPersonasFormularioByID",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getFormVehiclesById = async (id) => {
  try {
    const res = await axios.post(url, {
      IDFormulario: id,
      opcion: "getVehiculosFormularioByID",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const deleteVehicleById = async (formId, vehicleId) => {
  try {
    const res = await axios.post(url, {
      IDFormulario: formId,
      IDVehiculo: vehicleId,
      opcion: "deleteVehiculoFormulario",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const deleteVisitorById = async (formId, visitorId) => {
  try {
    const res = await axios.post(url, {
      IDFormulario: formId,
      IDPersona: visitorId,
      opcion: "deletePersonaFormulario",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const updateForm = async (formId, dateIn, dateOut, lot) => {
  try {
    const res = await axios.post(url, {
      IDFormulario: formId,
      fecha_ingreso: dateIn,
      fecha_egreso: dateOut,
      lote: lot,
      opcion: "updateFormulario",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const addVehicleToForm = async (formId, number, brand, model) => {
  try {
    const res = await axios.post(url, {
      IDFormulario: formId,
      patente: number,
      modelo: model,
      marca: brand,
      opcion: "setNuevoVehiculo",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const addVisitorToForm = async (
  formId,
  dni,
  firstName,
  lastName,
  minor
) => {
  try {
    const res = await axios.post(url, {
      IDFormulario: formId,
      dni: dni,
      nombre: firstName,
      apellido: lastName,
      es_menor: minor,
      opcion: "setNuevaPersona",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const addLog = async (dni, type) => {
  try {
    const res = await axios.post(url, {
      dni: dni,
      salio_entro: type,
      opcion: "insertarLog",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getDailyLogs = async () => {
  try {
    const res = await axios.post(url, {
      opcion: "movimientoDiario",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllLogs = async () => {
  try {
    const res = await axios.post(url, {
      opcion: "getMovimientos",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getPanelInfo = async () => {
  try {
    const res = await axios.post(url, {
      opcion: "getInfoPanel",
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

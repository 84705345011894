import React, { useEffect, useState } from "react";
import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { addLog, getDailyLogs } from "../services/serviceWorker";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    height: "100%",
  },
  item: {
    alignSelf: "center",
  },
  button: {
    width: "200px",
    height: "150px",
    fontSize: "20px",
    border: "3px solid",
  },
  input: {
    alignSelf: "center",
    textAlign: "center",
    marginTop: "-320px",
    marginLeft: "42%",
  },
}));

const Reception = () => {
  const [dni, setDni] = useState("");
  const [type, setType] = useState(1);
  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState(false);
  const [dailyLogs, setDailyLogs] = useState([]);
  const [input, setInput] = useState("");
  const [firstCall, setFirstCall] = useState(true);
  const classes = useStyles();
  const drawerWidth = 240;

  const fetchData = async () => {
    const result = await getDailyLogs();
    if (result.estado) {
      setDailyLogs(result.datos);
    } else {
      console.log("No se pudieron obtener los logs");
    }
  };

  useEffect(() => {
    if (firstCall) {
      fetchData();
    } else {
      setFirstCall(false);
    }
  });

  useEffect(() => {
    const log = async () => {
      console.log(dni, type);
      const result = await addLog(dni, type);
      if (result.estado) {
        setDni("");
        setType(1);
        setShow(true);
        setSeverity("success");
        setMessage(
          "Ingreso/Egreso completo. Ingresa a: " + result.datos[0].lote
        );
        fetchData();
      } else {
        setDni("");
        setType(1);
        setShow(true);
        setSeverity("error");
        setMessage("Hubo un error, intente nuevamente. " + result.descripcion);
      }
    };
    if (dni !== "") {
      log();
    }
  }, [dni, type]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const dni = event.target.value;
      let result = "";
      if (dni.length > 9) {
        result = dni.split(`"`).find((char) => {
          if (
            char.replaceAll(" ", "").length > 6 &&
            char.replaceAll(" ", "").length < 9 &&
            typeof parseInt(char) !== NaN &&
            typeof char !== NaN
          ) {
            return parseInt(char);
          }
        });
      } else {
        result = dni;
      }

      setDni(result);
      setInput("");
    }
  };

  const handleChangeInput = (event) => {
    setInput(event.target.value);
  };

  return (
    <>
      <Grid
        container
        position="fixed"
        className={classes.root}
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Snackbar
          open={show}
          autoHideDuration={2000}
          onClose={() => setShow(false)}
          style={{
            position: "absolute",
            paddingLeft: severity === "success" ? "38%" : "18%",
          }}
        >
          <Alert severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
        <Grid item xs={6} className={classes.item}>
          <Button
            variant="outlined"
            color="success"
            className={classes.button}
            style={{ backgroundColor: type === 1 ? "#9CEDA7" : "" }}
            onClick={() => setType(1)}
          >
            Entrada
          </Button>
          {dailyLogs[1] && (
            <Typography>Entradas de hoy: {dailyLogs[1].Cantidad}</Typography>
          )}
        </Grid>
        <Grid item xs={6} className={classes.item}>
          <Button
            variant="outlined"
            color="error"
            className={classes.button}
            style={{ backgroundColor: type === 0 ? "#ED9C9C" : "" }}
            onClick={() => setType(0)}
          >
            Salida
          </Button>
          {dailyLogs[0] && (
            <Typography>Salidas de hoy: {dailyLogs[0].Cantidad}</Typography>
          )}
        </Grid>
        <input
          className={classes.input}
          placeholder="DNI"
          autoFocus={true}
          onBlur={({ target }) => target.focus()}
          onKeyDown={(e) => handleKeyDown(e)}
          value={input}
          onChange={(e) => handleChangeInput(e)}
        />
      </Grid>
    </>
  );
};

export default Reception;

import React from "react";
import {
  Drawer,
  Toolbar,
  Divider,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ListItemIcon from "@mui/material/ListItemIcon";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const DrawerComponent = () => {
  const navigate = useNavigate();
  const options = [
    { id: 1, name: "Home", icon: <HomeIcon />, path: "/reception" },
    {
      id: 2,
      name: "Cargar Formulario",
      icon: <PlaylistAddIcon />,
      path: "/form",
    },
    {
      id: 3,
      name: "Actividad",
      icon: <PendingActionsIcon />,
      path: "/activity",
    },
    { id: 4, name: "Panel", icon: <MapsHomeWorkIcon />, path: "/panel" },
    {
      id: 5,
      name: "Listado Formularios",
      icon: <PlaylistAddCheckIcon />,
      path: "/list-forms",
    },
  ];
  return (
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <img
          src={logo}
          loading="lazy"
          alt={""}
          style={{ width: "120px", paddingLeft: "35px" }}
        />
      </Toolbar>
      <Divider />
      <List>
        {options.map((option) => (
          <ListItem
            button
            key={option.id}
            onClick={() => navigate(option.path)}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.name} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default DrawerComponent;

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import { getAllLogs } from "../services/serviceWorker";

const Activity = () => {
  const drawerWidth = 240;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllLogs();
      if (result.estado) {
        setData(result.datos);
      } else {
        console.log("No se pudieron obtener los logs");
      }
    };
    fetchData();
  }, [setData]);

  const columns = [
    { field: "id", headerName: "ID", width: 130 },
    { field: "type", headerName: "Tipo", width: 150 },
    { field: "time", headerName: "Fecha-Hora", width: 200 },
  ];

  let rows = [];
  rows = data.map((item, index) => {
    return (rows = {
      id: item.pklog,
      type: item.salio_entro === "0" ? "Salida" : "Entrada",
      time: item.fecha_hora,
    });
  });

  return (
    <Grid
      position="fixed"
      sx={{ width: `calc(100% - 255px)`, ml: `${drawerWidth}px` }}
      style={{ height: 630 }}
    >
      <DataGrid
        sortingOrder={["asc", "desc", null]}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Grid>
  );
};

export default Activity;

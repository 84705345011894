import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { getForms, updateForm } from "../../services/serviceWorker";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";

const StayDialogComponent = ({ open, close, id }) => {
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState([]);
  const [dateIn, setDateIn] = useState();
  const [dateOut, setDateOut] = useState();
  const [lot, setLot] = useState("");
  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState(false);

  useEffect(() => {
    const fetchFormsData = async () => {
      const result = await getForms();

      const form = result.datos.filter((forms) => forms.pkformulario === id);
      setDateIn(form[0].fecha_ingreso);
      setDateOut(form[0].fecha_egreso);
      setData(form);
    };
    if (open) {
      fetchFormsData();
    }
  }, [id, open]);

  const onClose = () => {
    close();
    setDisabled(true);
  };

  const onClickAccept = async () => {
    const updateFormById = await updateForm(id, dateIn, dateOut, lot);
    if (updateFormById.estado) {
      setShow(true);
      setSeverity("success");
      setMessage("Formulario actualizado correctamente.");
      close();
    } else {
      setShow(true);
      setSeverity("error");
      setMessage("Hubo un error, intente nuevamente.");
    }
  };
  const handleDateIn = (newValue) => {
    setDateIn(newValue);
  };
  const handleDateOut = (newValue) => {
    setDateOut(newValue);
  };

  return (
    <>
      <Snackbar
        open={show}
        autoHideDuration={1000}
        style={{ position: "absolute", bottom: "-200px", left: "50%" }}
      >
        <Alert severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Datos de la estadia"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={2} style={{ paddingTop: "40px" }}>
              <TextField
                onChange={(e) => setLot(e.target.value)}
                disabled={disabled}
                id="lot"
                defaultValue={data[0] ? data[0].lote : ""}
                variant="standard"
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={dateIn}
                  onChange={handleDateIn}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={dateOut}
                  onChange={handleDateOut}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} mt={"-3px"}>
              <IconButton
                aria-label="add"
                size="large"
                onClick={() => {
                  setDisabled(false);
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid item xs={1} mt={"-3px"}>
              <IconButton aria-label="add" size="large" onClick={() => {}}>
                <ClearIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClickAccept()}>Aceptar</Button>
          <Button onClick={() => onClose()}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StayDialogComponent;

import React, {useState, useEffect} from "react";
import {Checkbox, FormControlLabel, FormGroup,   Snackbar, Alert, DialogActions, IconButton, Grid, DialogContent, Button, Dialog, DialogTitle, TextField } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { deleteVisitorById, getFormVisitorsById, addVisitorToForm } from "../../services/serviceWorker";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    checkbox:{
        alignSelf: 'center',
        marginTop: '6px'
    },
}));

const VisitorsDialogComponent = ({ open, close, id }) => {
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [message, setMessage] = useState('');
    const [addRow, setAddRow] = useState(false);
    const [dni, setDni] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [minor, setMinor] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            const result = await getFormVisitorsById(id);
            setData(result.datos);
        };
        if (open){
            fetchData();
        }
        
    }, [id, open]);

    const onClose = () => {
        close();
        setDisabled(true)
    }

    const onClickDeleteVisitor = async (visitor) => {
        const deleteVisitor = await deleteVisitorById(id, visitor);
        if (deleteVisitor.estado) {
            setShow(true)
            setSeverity("success")
            setMessage('Visitante eliminado del formulario.')
            setDni('')
            setFirstName('')
            setLastName('')
          } else {
            setShow(true)
            setSeverity("error")
            setMessage('Hubo un error, intente nuevamente.')
          }
    }

    const onClickSave = async () => {
        if (dni && firstName && lastName) {
            const addVisitor = await addVisitorToForm(id, dni, firstName, lastName, minor)
            if (addVisitor.estado){
                close();
                setShow(true)
                setSeverity("success")
                setMessage('Persona añadida al formulario.')
              } else {
                setShow(true)
                setSeverity("error")
                setMessage('Hubo un error, intente nuevamente.')
            }
        }
    }

    const formRow = () => {
        return(
            <Grid container spacing={2} >
                        <Grid item xs={3}>
                            <TextField
                                id="dni"
                                defaultValue={dni}
                                variant="standard"
                                placeholder="DNI"
                                onChange={(e) => setDni(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="firstName"
                                defaultValue={firstName}
                                variant="standard"
                                placeholder="Nombre"
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="lastName"
                                defaultValue={lastName}
                                variant="standard"
                                placeholder="Apellido"
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                                    <FormGroup className={classes.checkbox}>
                                        <FormControlLabel control={<Checkbox />} label="Menor?" onChange={(e) => setMinor(e.target.checked)}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3}>
                            <Button onClick={()=>onClickSave()} variant="contained" style={{textAlign:'center'}}>Guardar</Button>
                        </Grid>
                    </Grid>
        )
    }

    return(
        <>
        <Snackbar open={show} autoHideDuration={1000} style={{position: "absolute", bottom: '-200px', left:'50%'}}>
          <Alert severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
        <Dialog
            open={open}
            onClose={()=>{onClose()}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Datos de los integrantes"}
            </DialogTitle>
            <DialogContent>
            {data.map((item, index) => {
                return(
                <Grid container spacing={2} key={item.pkpersona}>
                        <Grid item xs={3}>
                            <TextField
                                disabled={disabled}
                                id="dni"
                                defaultValue={item.dni_persona}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                disabled={disabled}
                                id="firstName"
                                defaultValue={item.nombre_persona}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                disabled={disabled}
                                id="lastName"
                                defaultValue={item.apellido_persona}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={1} mt={'-3px'}>
                            <IconButton aria-label="add" size="large" 
                                onClick={() => setDisabled(false)}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} mt={'-3px'}>
                            <IconButton aria-label="add" size="large" 
                                onClick={() => setAddRow(true)}
                            >
                                <AddIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} mt={'-3px'}>
                            <IconButton aria-label="add" size="large" 
                                onClick={() => onClickDeleteVisitor()}
                            >
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                </Grid> 
                )
            })}
            {addRow && formRow()}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{onClose()}}>Cerrar</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default VisitorsDialogComponent;

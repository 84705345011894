import React, { useState } from "react";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../services/serviceWorker";
import logo from "../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  image: {
    width: "200px",
    paddingLeft: "10px",
  },
}));

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const login = await loginUser(user, password);
    if (login.estado) {
      localStorage.setItem("usuario", login.datos[0].pkusuario);
      setShow(true);
      setSeverity("success");
      setMessage("Login completo.");
      navigate("/reception");
    } else {
      setShow(true);
      setSeverity("error");
      setMessage("Hubo un error, intente nuevamente.");
    }
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <img src={logo} loading="lazy" alt={""} className={classes.image} />
      <TextField
        label="Usuario"
        variant="filled"
        type="text"
        required
        value={user}
        onChange={(e) => setUser(e.target.value)}
      />
      <TextField
        label="Password"
        variant="filled"
        type="password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div>
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
        <Snackbar open={show} autoHideDuration={2000}>
          <Alert severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </form>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Button } from "@mui/material";
import VisitorsDialogComponent from "./common/VisitorsDialog";
import VehiclesDialogComponent from "./common/VehiclesDialog";
import StayDialogComponent from "./common/StayDialog";
import { getForms } from "../services/serviceWorker";

const ListForms = () => {
  const [showVisitorsModal, setShowVisitorsModal] = useState(false);
  const [showVehiclesModal, setShowVehiclesModal] = useState(false);
  const [showStayModal, setShowStayModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState();
  const [data, setData] = useState([]);

  const drawerWidth = 240;

  useEffect(() => {
    const fetchData = async () => {
      const result = await getForms();
      setData(result.datos);
    };
    fetchData();
  }, []);

  const onClickVisitors = (cellValues) => {
    setSelectedForm(cellValues.id);
    setShowVisitorsModal(true);
  };

  const onClickVehicles = (cellValues) => {
    setSelectedForm(cellValues.id);
    setShowVehiclesModal(true);
  };

  const onClickStay = (cellValues) => {
    setSelectedForm(cellValues.id);
    setShowStayModal(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 30,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "responsable",
      headerName: "Responsable",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "vehicle",
      headerName: "Vehiculo",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lot",
      headerName: "Lote",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ingreso",
      headerName: "Fecha Ingreso",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "egreso",
      headerName: "Fecha Egreso",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Ver Integrantes",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            id={data.pkformulario}
            onClick={() => {
              onClickVisitors(cellValues);
            }}
          >
            Ver
          </Button>
        );
      },
    },
    {
      field: "Ver vehículos",
      width: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            id={data.pkformulario}
            onClick={() => {
              onClickVehicles(cellValues);
            }}
          >
            Ver
          </Button>
        );
      },
    },
    {
      field: "Ver Estadia",
      width: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClickStay(cellValues);
            }}
          >
            Ver
          </Button>
        );
      },
    },
  ];

  let rows = [];
  rows = data.map((item, index) => {
    return (rows = {
      id: item.pkformulario,
      responsable: item.dni_persona,
      vehicle: "IBZ740",
      lot: item.lote,
      ingreso: item.fecha_ingreso,
      egreso: item.fecha_egreso,
    });
  });

  return (
    <Grid
      position="fixed"
      sx={{ width: `calc(100% - 255px)`, ml: `${drawerWidth}px` }}
      style={{ height: 630 }}
    >
      <DataGrid
        sortingOrder={["asc", "desc", null]}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
      <VisitorsDialogComponent
        open={showVisitorsModal}
        close={() => {
          setShowVisitorsModal(false);
        }}
        id={selectedForm}
      />
      <VehiclesDialogComponent
        open={showVehiclesModal}
        close={() => {
          setShowVehiclesModal(false);
        }}
        id={selectedForm}
      />
      <StayDialogComponent
        open={showStayModal}
        close={() => {
          setShowStayModal(false);
        }}
        id={selectedForm}
      />
    </Grid>
  );
};

export default ListForms;

import React, { useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { postForm } from "../services/serviceWorker";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import esLocale from "date-fns/locale/ES";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    alignSelf: "center",
    marginTop: "6px",
  },
  datepicker: {
    lineHeight: "1.4375em",
  },
}));

const Form = () => {
  const drawerWidth = 240;
  const classes = useStyles();
  const [visitorsForm, setVisitorsForm] = useState([0]);
  const [vehicles, setVehicles] = useState([]);
  const [dni, setDni] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [responsible, setResponsible] = useState(true);
  const [minor, setMinor] = useState(false);
  const [lot, setLot] = useState("");
  const [dateIn, setDateIn] = useState(new Date());
  const [dateOut, setDateOut] = useState(new Date());
  const [visitors, setVisitors] = useState([]);
  const [vehiclesForms, setVehiclesForms] = useState([0]);
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [number, setNumber] = useState("");
  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState(false);

  const handleVisitors = () => {
    const data = {
      dni: dni,
      nombre: firstName,
      apellido: lastName,
      responsable: responsible,
      es_menor: minor,
    };
    setVisitors((visitors) => [...visitors, data]);
  };

  const handleVehicles = () => {
    const data = {
      marca: brand,
      modelo: model,
      patente: number,
    };
    setVehicles((vehicles) => [...vehicles, data]);
  };

  const handleSubmit = async () => {
    const user = localStorage.getItem("usuario");
    const data = {
      lot: lot,
      dateIn: dateIn,
      dateOut: dateOut,
    };

    const visitorsData = visitors.concat({
      dni: dni,
      nombre: firstName,
      apellido: lastName,
      responsable: responsible,
      es_menor: minor,
    });

    const vehiclesData = vehicles.concat({
      marca: brand,
      modelo: model,
      patente: number,
    });
    const sendFormData = await postForm(
      visitorsData,
      vehiclesData,
      data.dateIn.toISOString().slice(0, 10),
      data.dateOut.toISOString().slice(0, 10),
      data.lot,
      parseInt(user)
    );
    if (sendFormData.estado) {
      setShow(true);
      setSeverity("success");
      setMessage("Formulario cargado.");
      window.location.reload();
    } else {
      setShow(true);
      setSeverity("error");
      setMessage("Hubo un error, intente nuevamente.");
    }
  };

  const handleDateIn = (newValue) => {
    setDateIn(newValue);
  };
  const handleDateOut = (newValue) => {
    setDateOut(newValue);
  };

  return (
    <>
      <Snackbar open={show} autoHideDuration={2000}>
        <Alert severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Grid
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <form>
          <Typography style={{ marginBottom: "30px" }}>
            Planilla - KM314
          </Typography>
          <Grid container spacing={6}>
            <Grid item xs={2}>
              <TextField
                onChange={(e) => setLot(e.target.value)}
                label="Lote"
                type="text"
                variant="standard"
                required
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "-10px" }}>
              <LocalizationProvider dateAdapter={DateAdapter} locale={esLocale}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={dateIn}
                  onChange={handleDateIn}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "-10px" }}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={dateOut}
                  onChange={handleDateOut}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <br />
          <Typography>Datos de los integrantes</Typography>
          {visitorsForm.map(function (name, index) {
            return (
              <Grid container key={index} spacing={6}>
                <Grid item xs={2}>
                  <TextField
                    onChange={(e) => setDni(e.target.value)}
                    label="DNI"
                    type="text"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    onChange={(e) => setFirstName(e.target.value)}
                    label="Nombre"
                    type="text"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    onChange={(e) => setLastName(e.target.value)}
                    label="Apellido"
                    type="text"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormGroup className={classes.checkbox}>
                    <FormControlLabel
                      control={
                        index === 0 ? <Checkbox defaultChecked /> : <Checkbox />
                      }
                      label="Responsable?"
                      onChange={(e) => setResponsible(e.target.checked)}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={2}>
                  <FormGroup className={classes.checkbox}>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Menor?"
                      onChange={(e) => setMinor(e.target.checked)}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="add"
                    size="large"
                    onClick={() => {
                      setVisitorsForm([...visitorsForm, 0]);
                      setResponsible(false);
                      handleVisitors();
                    }}
                  >
                    <AddCircleIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <br></br>
          <Typography>Datos de los vehiculos</Typography>
          {vehiclesForms.map(function (name, index) {
            return (
              <Grid container key={index} spacing={6}>
                <Grid item xs={2}>
                  <TextField
                    onChange={(e) => setBrand(e.target.value)}
                    label="Marca"
                    type="text"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    onChange={(e) => setModel(e.target.value)}
                    label="Modelo"
                    type="text"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    onChange={(e) => setNumber(e.target.value)}
                    label="Patente"
                    type="text"
                    variant="standard"
                    required
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="add"
                    size="large"
                    onClick={() => {
                      setVehiclesForms([...vehiclesForms, 0]);
                      handleVehicles();
                    }}
                  >
                    <AddCircleIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <br></br>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Guardar
          </Button>
        </form>
      </Grid>
    </>
  );
};

export default Form;

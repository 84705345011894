import React, { useEffect, useState } from "react";
import { Paper, Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { getDailyLogs, getPanelInfo } from "../services/serviceWorker";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  item: {
    height: "100%",
  },
}));

const Panel = () => {
  const classes = useStyles();
  const drawerWidth = 240;
  const [data, setData] = useState([]);
  const [firstCall, setFirstCall] = useState(true);

  const fetchData = async () => {
    const result = await getPanelInfo();
    console.log(result);
    if (result.estado) {
      setData(result.datos);
    } else {
      console.log("No se pudo obtener la info para el panel");
    }
  };

  useEffect(() => {
    if (firstCall) {
      fetchData();
    } else {
      setFirstCall(false);
    }
  }, [firstCall]);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Typography
        textAlign={"center"}
        variant="h4"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
        }}
      >
        Panel informativo diario{" "}
      </Typography>
      <Grid
        justifyContent={"center"}
        position="fixed"
        className={classes.root}
        container
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          mt: "20px",
        }}
      >
        {data.length > 0 ? (
          data.map(function (name, index) {
            return (
              <Item>
                <Grid item>
                  <span style={{ marginLeft: "10px", float: "left" }}>
                    Lote:
                  </span>
                  <span style={{ marginRight: "10px", float: "right" }}>
                    {name.lote}
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ marginLeft: "10px", float: "left" }}>
                    Cant.Personas:
                  </span>
                  <span style={{ marginRight: "10px", float: "right" }}>
                    {name.cant_personas}
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ marginLeft: "10px", float: "left" }}>
                    Cant.Vehículos:
                  </span>
                  <span style={{ marginRight: "10px", float: "right" }}>
                    {name.cant_vehiculos}
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ marginLeft: "10px", float: "left" }}>
                    Ingresaron?:
                  </span>
                  <span style={{ marginRight: "10px", float: "right" }}>
                    {name.accedio === null ? "No" : "Si"}
                  </span>
                </Grid>
              </Item>
            );
          })
        ) : (
          <Typography
            textAlign={"center"}
            variant="body"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
            }}
          >
            No hay registros para el día de hoy
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default Panel;

import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import DrawerComponent from "./components/common/Drawer";
import Login from "./components/Login";
import Panel from "./components/Panel";
import Activity from "./components/Activity";
import Form from "./components/Form";
import Reception from "./components/Reception";
import ListForms from "./components/ListForms";

const App = () => {
  let location = useLocation();
  return (
    <div>
      {location.pathname === "/" || location.pathname === "/login" ? (
        ""
      ) : (
        <DrawerComponent />
      )}
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/panel" element={<Panel />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/form" element={<Form />} />
        <Route path="/reception" element={<Reception />} />
        <Route path="/list-forms" element={<ListForms />} />
      </Routes>
    </div>
  );
};

export default App;

import React, {useState, useEffect} from "react";
import { Snackbar, Alert, DialogActions, IconButton, Grid, DialogContent, Button, Dialog, DialogTitle, TextField } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { deleteVehicleById, getFormVehiclesById, addVehicleToForm } from "../../services/serviceWorker";

const VehiclesDialogComponent = ({ open, close, id }) => {
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [message, setMessage] = useState(false);
    const [addRow, setAddRow] = useState(false);
    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');
    const [number, setNumber] = useState('');

    useEffect(() => {
        const fetchDataVehiculos = async () => {
            const result = await getFormVehiclesById(id);
            setData(result.datos);
        };
        if (open){
            fetchDataVehiculos();
        }
    }, [id, open]);

    
    const onClose = () => {
        close();
        setDisabled(true)
    }

    const onClickSave = async () => {
        if (number && brand && model) {
            const addVehicle = await addVehicleToForm(id, number, brand, model)
            if (addVehicle.estado){
                setShow(true)
                setSeverity("success")
                setMessage('Vehículo añadido al formulario.')
                close();
              } else {
                setShow(true)
                setSeverity("error")
                setMessage('Hubo un error, intente nuevamente.')
            }
        }
    }

    const onClickDeleteVehicle = async (vehiculo) => {
        const deleteVehicle = await deleteVehicleById(id, vehiculo);
        if (deleteVehicle.estado) {
                setShow(true)
                setSeverity("success")
                setMessage('Vehículo eliminado del formulario.')
                setAddRow(false)
                setNumber('')
                setBrand('')
                setModel('')
              } else {
                setShow(true)
                setSeverity("error")
                setMessage('Hubo un error, intente nuevamente.')
              }
    }

    const formRow = () => {
        return(
            <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                id="number"
                                defaultValue={number}
                                variant="standard"
                                placeholder="Patente"
                                onChange={(e) => setNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="brand"
                                defaultValue={brand}
                                variant="standard"
                                placeholder="Marca"
                                onChange={(e) => setBrand(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="model"
                                defaultValue={model}
                                variant="standard"
                                placeholder="Modelo"
                                onChange={(e) => setModel(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button onClick={()=>onClickSave()} variant="contained" style={{textAlign:'center'}}>Guardar</Button>
                        </Grid>
                        </Grid>
        )
    }

    return(
        <>
        <Snackbar open={show} autoHideDuration={1000} style={{position: "absolute", bottom: '-200px', left:'50%'}}>
          <Alert severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
        <Dialog
            open={open}
            onClose={()=>{onClose()}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Datos de los vehículos"}
            </DialogTitle>
            <DialogContent>
            {data.map((item, index) => {
                return(
                    <Grid container spacing={2} key={item.pkvehiculo}>
                        <Grid item xs={3}>
                            <TextField
                                disabled={disabled}
                                id="number"
                                defaultValue={item.patente}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                disabled={disabled}
                                id="brand"
                                defaultValue={item.marca}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                disabled={disabled}
                                id="model"
                                defaultValue={item.modelo}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={1} mt={'-3px'}>
                            <IconButton aria-label="add" size="large" 
                                onClick={() => setDisabled(false)}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} mt={'-3px'}>
                            <IconButton aria-label="add" size="large" 
                                onClick={() => setAddRow(true)}
                            >
                                <AddIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={1} mt={'-3px'}>
                            <IconButton aria-label="add" size="large" 
                                onClick={() => onClickDeleteVehicle(item.pkvehiculo)}
                            >
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid> 
                )
            })}
            {addRow && formRow()}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>onClose()}>Cerrar</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default VehiclesDialogComponent;
